import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import {
  setTranslations,
  setLanguage,
  translate,
} from 'react-switch-lang';


import en from './assets/language/en.json';
import cz from './assets/language/cz.json';

import { log, getApiURL } from "./helpers";

import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
import Cookies from 'universal-cookie';
import Moment from 'react-moment'
import ReactGA from "react-ga4";
import axios from 'axios';

ReactGA.initialize("G-3RFX78YPS1");

setTranslations({ en, cz });
//setDefaultLanguage('cz');

const isProduction = process.env.NODE_ENV === 'production';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      userData: "",
      refreshIntervalRef: null,
    };
  }

  loginCallback = (user, lang) => {
    log("Z callbacku");
    log(user);
    this.setState({ userData: user })
    localStorage.setItem("token", user.jwtToken);
    localStorage.setItem("userId", user.id);
    localStorage.setItem("lastUpdate", Date.now());
    localStorage.setItem("language", lang)
    setLanguage(lang);
  }

  logoutCallback = () => {
    this.setState({ userData: "" });
    localStorage.removeItem("token");
    localStorage.removeItem("lastUpdate");
    localStorage.removeItem("userId");
    localStorage.removeItem("language");
  }

  //  TODO -- refreshToken = async (token, userId) => {
  //     let URL = getApiURL("new") + "/Accounts/refresh-token";
  //     // let method = "POST";
  //     // let bearerToken = "Bearer " + token;

  //     const headers = {
  //       accept: "text/plain",
  //       Authorization: `Bearer ${token}`,
  //       "Content-Type": "application/json",
  //       "Access-Control-Allow-Origin": "*",
  //       "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  //       //"Cookie": document.cookie
  //     };

  //     const response = await axios.post(URL, null, {
  //       withCredentials: true, // TODO - causes CORS problems, but adds necessary Cookie atribut to request
  //       headers:
  //         headers,
  //     });

  //     //console.log(response);
  //     if (response?.data) {
  //       if (response?.data?.jwtToken) {
  //         this.setState({ userData: response.data });
  //       }
  //     }
  //     else {
  //       log("cannot refresh token - response.status: ", response.status);
  //     }
  //     return;

  //     // await fetch(URL, {
  //     //   method: method,
  //     //   mode: "cors",
  //     //   cache: "no-cache",
  //     //   credentials: "include",
  //     //   headers: {
  //     //     "Content-Type": "application/json",
  //     //     "Accepts": "text/plain",
  //     //     'Cookie': 'refreshToken=02A545B504D085DE49905949E5AE0675F3228ACE6E3CADFC26207704F5983EDED6BA13E21B0B0A1F'
  //     //   },
  //     //   redirect: "follow",
  //     // })
  //     //   .then((response) => response.json())
  //     //   .then((json) => {
  //     //     log("get updated user data");
  //     //     log(json);
  //     //     json.jwtToken = token;
  //     //     if (json?.jwtToken) {
  //     //       this.setState({ userData: json });
  //     //     }
  //     //   })
  //     //   .catch((error) => {
  //     //     log(error);
  //     //     log("cannot refresh");
  //     //   });
  //   }

  refreshToken = async (token, userId) => {
    // const cookies = new Cookies();
    let URL = getApiURL() + "/Accounts/" + userId;
    let method = "GET";
    let bearerToken = "Bearer " + token;
    //log("Cookie:");
    // log(cookies.get('refreshToken'));
    await fetch(URL, {
      method: method,
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerToken,
        // Cookie: "refreshToken=" + cookies.get('refreshToken')
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    })
      .then((response) => response.json())
      .then((json) => {
        log("get updated user data");
        json.jwtToken = token;
        this.setState({ userData: json });
        log(json);
      })
      .catch((error) => {
        log(error);
        log("cannot refresh");
      })
  };

  checkToken = async (token) => {
    const cookies = new Cookies();
    let URL = getApiURL() + "/Company/Get";
    let method = "POST";
    let bearerToken = "Bearer " + token;
    let dataTypeBody = {};
    // log("Cookie:");
    log(cookies.get('refreshToken'));
    await fetch(URL, {
      method: method,
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerToken,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(dataTypeBody),
    })
      .then((response) => response.json())
      .then((json) => {
        log("get updated user data");
        //this.setState({ userData: json });
        log(json);


      })
      .catch((error) => {
        log(error);
        log("cannot refresh");

      });

  }

  componentDidMount() {
    log("web loaded - yeah");
    if (localStorage.getItem("token") && localStorage.getItem("lastUpdate")) {

      if ((Number(localStorage.getItem("lastUpdate")) + 1000 * 60 * 60) > Date.now()) {
        this.refreshToken(localStorage.getItem("token"), localStorage.getItem("userId"));
        // this.checkToken(localStorage.getItem("token"));
      }

      if (localStorage.getItem("language")) {
        setLanguage(localStorage.getItem("language"));
      }
    }

    // if (localStorage.getItem("token") && localStorage.getItem("lastUpdate")) {
    //   if ((Number(localStorage.getItem("lastUpdate")) + 1000 * 60 * 60) > Date.now()) {

    //     this.refreshToken(localStorage.getItem("token"), localStorage.getItem("userId"));
    //     // this.checkToken(localStorage.getItem("token"));
    //   }
    // }

    // Set the initial refresh interval
    // const refresh = setInterval(() => {
    //   this.refreshToken(localStorage.getItem("token"), localStorage.getItem("userId"))
    // }, 20 * 60 * 1000); // 20 minutes

    // this.setState({ refreshIntervalRef: refresh });
    // }
  }

  componentWillUnmount() {
    //clearInterval(this.state.refreshIntervalRef);
  }

  render() {

    return (
      <CacheBuster
        currentVersion={version}
        isEnabled={isProduction} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        loadingComponent={loading} //If not pass, nothing appears at the time of new version check.
      >
        <HashRouter>
          <React.Suspense fallback={loading}>

            {this.state.userData === "" && (
              <Route path="/" name="Login Page" render={props => <Login loginCallback={this.loginCallback} />} />
            )}

            {this.state.userData !== "" && (
              <Switch>
                <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
                <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
                <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
                <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
                <Route path="/" name="Home" render={props => <TheLayout loginCallback={this.loginCallback} logoutCallback={this.logoutCallback} userData={this.state.userData} />} />
              </Switch>
            )}
          </React.Suspense>
        </HashRouter>
      </CacheBuster>
    );
  }
}

// Login.propTypes = {
//   t: PropTypes.func.isRequired,
// };

export default translate(App);
